/* eslint-disable no-nested-ternary */
import React from 'react';

const UserDetailsBasicAfter = ({ user }) => {
  const { additionalData } = user;
  console.warn('user', additionalData);

  return (
    <div style={{ marginBottom: 20 }} className="col-lg-6">
      <h4>Additional Info</h4>
      <hr />
      <dl>
        <dt>Website:</dt>
        <dd>{additionalData.website || '-'}</dd>
        <dt>Company Description:</dt>
        <dd>{additionalData.companyDescription || '-'}</dd>
        <dt>Annual Turnover:</dt>
        <dd>{additionalData.annualTurnover || '-'}</dd>
        <dt>Years in Business:</dt>
        <dd>{additionalData.yearsInBusiness || '-'}</dd>
        <dt>Use of Account:</dt>
        <dd>{additionalData.useOfAccount || '-'}</dd>
        <dt>Expected Monthly Volume:</dt>
        <dd>{additionalData.expectedMonthlyVolume || '-'}</dd>
        <dt>Transfers In:</dt>
        <dd>{additionalData.transfersIn || '-'}</dd>
        <dt>Transfers Out:</dt>
        <dd>{additionalData.transfersOut || '-'}</dd>
      </dl>
    </div>
  );
};

export default UserDetailsBasicAfter;
